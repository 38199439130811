import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { sendRequest, sendJobRequest, sendSiteRequest, uploadBlobToAzure, sendChainRequest } from '../api/api';
import * as jobConstants from '../lib/jobConstants';

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async ({ chainId, siteId }, API) => {
  const state = API.getState();
  const queryData = {
    procName: 'GetJobsInSite',
    data: { siteId },
  };
  const jobs = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return jobs || [];
});

export const fetchJobQueue = createAsyncThunk('jobs/fetchJobQueue', async ({ chainId, siteId }, API) => {
  const state = API.getState();
  const queryData = {
    procName: 'GetJobQueue',
    data: { siteId },
  };
  const jobs = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return jobs || [];
});

export const fetchJobQueueJob = createAsyncThunk('jobs/fetchJobQueueJob', async ({ jobId }, API) => {
  const state = API.getState();
  const selectedChainId = state.chains.selectedChainId;
  const selectedSiteId = state.sites.selectedSiteId;
  const queryData = {
    procName: 'GetJobQueueJob',
    data: {
      chainId: selectedChainId,
      siteId: selectedSiteId,
      jobId
    },
  };
  const job = await sendRequest(selectedChainId, queryData, 'POST', state.auth.token);
  return job || {};
});

export const updateJob = createAsyncThunk('jobs/updateJob', async ({ chainId, job }, API) => {
  const state = API.getState();
  await sendJobRequest(chainId, job.id, 'PUT', '', job, '', state.auth.token);
  return job;
});

export const deleteJob = createAsyncThunk('jobs/deleteJob', async ({ chainId, jobId }, API) => {
  const state = API.getState();
  await sendJobRequest(chainId, jobId, 'DELETE', '', null, '', state.auth.token);
  return jobId;
});

export const updateJobVehicle = createAsyncThunk('jobs/updateJobVehicle', async ({ chainId, data }, API) => {
  try {
    const state = API.getState();
    const jobId = await sendJobRequest(chainId, data.jobId, 'PUT', '/vehicle', data, '', state.auth.token);
    return jobId;
  } catch (error) {
    return API.rejectWithValue(error);
  }
});

export const fetchJob = createAsyncThunk('jobs/fetchJob', async ({ chainId, jobId, language, includePreviousFaults = false }, API) => {
  const state = API.getState();
  let params = `&language=${language}`;
  if (includePreviousFaults) {
    params += '&includePreviousFaults=true';
  }
  const job = await sendJobRequest(chainId, jobId, 'GET', '', null, params, state.auth.token);
  return job;
});

export const fetchConditionInspectionJobId = createAsyncThunk('jobs/fetchConditionInspectionJobId', async ({ chainId, siteId, jobId }, API) => {
  const state = API.getState();
  const queryData = {
    procName: 'GetConditionInspectionJobIdByJobId',
    data: {
      chainId,
      siteId,
      jobId,
    },
  };
  const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return result.id;
});

export const fetchRequiredMeasurements = createAsyncThunk('jobs/fetchRequiredMeasurements', async ({ chainId, jobId }, API) => {
  try {
    const state = API.getState();
    const job = await sendJobRequest(chainId, jobId, 'GET', '/requiredMeasurements', null, '', state.auth.token);
    return job;
  } catch (error) {
    return API.rejectWithValue(error);
  }
});

export const fetchJobImages = createAsyncThunk('jobs/fetchJobImages', async ({ chainId, jobId }, API) => {
  try {
    const state = API.getState();
    const response = await sendJobRequest(chainId, jobId, 'GET', '/images', null, '', state.auth.token);
    return response;
  } catch (error) {
    return API.rejectWithValue(error);
  }
});

export const uploadImage = createAsyncThunk('jobs/uploadImage', async ({
  chainId,
  siteId,
  jobId,
  userId,
  image,
  description,
  resizedImage,
  thumbnailImage,
  measurementTypeId,
  vehicleSide = null,
  vehicleDamageId = null }, API) => {

  // Fetch SAS token.
  const fileNameParts = image.name.split('.');
  const extension = '.' + fileNameParts[fileNameParts.length - 1].toLowerCase();
  const state = API.getState();
  const tokenData = await sendSiteRequest(chainId, siteId, 'GET', '/blobImageToken', `&extension=${extension}`, null, state.auth.token);

  // Upload images to blob storage.
  await Promise.all([
    uploadBlobToAzure(tokenData.account, tokenData.imageContainer, tokenData.imageSas, tokenData.blobName, resizedImage),
    uploadBlobToAzure(tokenData.account, tokenData.thumbnailContainer, tokenData.thumbnailSas, tokenData.blobName, thumbnailImage),
  ]);

  // Save image to DB.
  const queryData = {
    procName: 'AddVehicleImage',
    data: {
      jobId,
      addedBy: userId,
      guid: tokenData.resourceId,
      name: image.name,
      extension: tokenData.extension,
      description,
      contentLocation: tokenData.imageResourceUri,
      measurementTypeId,
      vehicleSide,
      vehicleDamageId,
    },
  };
  const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return result;
});

export const uploadVideo = createAsyncThunk('jobs/uploadVideo', async ({
  chainId,
  siteId,
  jobId,
  userId,
  videoBlob,
  description,
  measurementTypeId }, API) => {

  // Fetch SAS token.
  const state = API.getState();
  const extension = '.webm';
  const tokenData = await sendSiteRequest(chainId, siteId, 'GET', '/blobImageToken', `&extension=${extension}`, null, state.auth.token);

  // Upload video to blob storage.
  await uploadBlobToAzure(tokenData.account, tokenData.imageContainer, tokenData.imageSas, tokenData.blobName, videoBlob);

  // Save image to DB.
  const queryData = {
    procName: 'AddVehicleImage',
    data: {
      jobId,
      addedBy: userId,
      guid: tokenData.resourceId,
      name: `${dayjs().format('YYYYMMDDHHmmss')}.webm`,
      extension: tokenData.extension,
      description,
      contentLocation: tokenData.imageResourceUri,
      measurementTypeId,
      isVideo: true,
      vehicleSide: null,
      vehicleDamageId: null,
    },
  };
  const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return result;
});

export const deleteImage = createAsyncThunk('jobs/deleteImage', async ({ chainId, jobId, imageId }, API) => {
  const state = API.getState();
  // Delete image from DB.
  await sendJobRequest(chainId, jobId, 'DELETE', `/image/${imageId}`, null, '', state.auth.token);

  // Return image ID.
  return {
    id: imageId,
  };
});

export const fetchVehicleColors = createAsyncThunk('jobs/fetchVehicleColors', async ({ chainId, language = 'fi' }, API) => {
  const state = API.getState();
  return await sendChainRequest(chainId, 'GET', '/vehicleColors', `&language=${language}`, null, state.auth.token);
});

export const fetchFuelTypes = createAsyncThunk('jobs/fetchFuelTypes', async ({ chainId, language = 'fi' }, API) => {
  const state = API.getState();
  return await sendChainRequest(chainId, 'GET', '/fuels', `&language=${language}`, null, state.auth.token);
});

export const fetchVehicleClasses = createAsyncThunk('jobs/fetchVehicleClasses', async ({ chainId, language = 'fi' }, API) => {
  const state = API.getState();
  return await sendChainRequest(chainId, 'GET', '/vehicleClassList', `&language=${language}`, null, state.auth.token);
});

export const fetchVehicleBrands = createAsyncThunk('jobs/fetchVehicleBrands', async ({ chainId, language = 'fi' }, API) => {
  const state = API.getState();
  return await sendChainRequest(chainId, 'GET', '/vehicleBrands', `&language=${language}`, null, state.auth.token);
});

export const negotiateMeasurement = createAsyncThunk('jobs/negotiateMeasurement', async ({ chainId, jobId, measurementRequest }, API) => {
  if (measurementRequest) {
    const state = API.getState();

    const result = await sendJobRequest(chainId, jobId, 'POST', '/negotiateMeasurement', {}, '&mid=' + measurementRequest.measurementId, state.auth.token);

    return result;
  } else {
    return null;
  }
});

export const receiveMeasurementResult = createAsyncThunk('jobs/receiveMeasurementResult', async (result, API) => {
  const state = API.getState();

  return result;
});

export const updateMeasurementInformation = createAsyncThunk('jobs/updateMeasurementInformation', async ({ chainId, jobId, data, save = true }, API) => {
  const state = API.getState();
  const job = state.jobs.job;
  let measurementInfo = [...(job.measurementInformation || [])];

  // If data is an object, turn it into array.
  const arrayData = Array.isArray(data) ? data : [data];
  arrayData.forEach((item) => {
    // Find updated measurement.
    const index = measurementInfo.findIndex((m) => m.measurementType.name === item.measurementType.name);
    if (index === -1) {
      measurementInfo = measurementInfo.concat(item);
    } else {
      measurementInfo.splice(index, 1, item);
    }
  });

  if (save) {
    await sendJobRequest(chainId, jobId, 'PUT', '/measurementInformation', measurementInfo, '', state.auth.token);
  }

  return measurementInfo;
});

export const updateFaultInformation = createAsyncThunk('jobs/updateFaultInformation', async ({ chainId, jobId, data }, API) => {
  const state = API.getState();
  await sendJobRequest(chainId, jobId, 'PUT', '/faultInformation', data, '', state.auth.token);
  return data;
});

export const updateVehicleInformation = createAsyncThunk('jobs/updateVehicleInformation', async ({ chainId, jobId, data, language }, API) => {
  const state = API.getState();
  await sendJobRequest(chainId, jobId, 'PUT', '/vehicleInformation', data, '', state.auth.token);
  const job = await sendJobRequest(chainId, jobId, 'GET', '', null, `&language=${language}`, state.auth.token); // Fetch job, otherwise vehicleInformation.fuel is missing.
  return job;
});

export const updateHaynesInformation = createAsyncThunk('jobs/updateHaynesInformation', async ({ chainId, jobId, data }, API) => {
  const state = API.getState();
  const haynesInformation = await sendJobRequest(chainId, jobId, 'PUT', '/haynesInformation', data, '', state.auth.token);
  return haynesInformation;
});

export const createFault = createAsyncThunk('jobs/createFault', async ({ chainId, jobId, data }, API) => {
  const state = API.getState();
  const faults = await sendJobRequest(chainId, jobId, 'POST', '/addFault', data, '', state.auth.token);
  return faults;
});

export const fetchFavoriteFaults = createAsyncThunk('jobs/fetchFavoriteFaults', async ({ chainId, userId }, API) => {
  const queryData = {
    procName: 'GetFavoriteFaults',
    data: { userId },
  };
  const state = API.getState();
  const favoriteFaults = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return favoriteFaults ? favoriteFaults.faultInformation : [];
});

export const updateFavoriteFaults = createAsyncThunk('jobs/updateFavoriteFaults', async ({ chainId, userId, faultInformation }, API) => {
  const queryData = {
    procName: 'SetFavoriteFault',
    data: { userId, faultInformation },
  };
  const state = API.getState();
  await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return faultInformation;
});

export const finalizeJob = createAsyncThunk('jobs/finalizeJob', async ({ chainId, jobId, data }, API) => {
  const state = API.getState();
  return await sendJobRequest(chainId, jobId, 'POST', '/finalizeJob', data, '', state.auth.token);
});

export const fetchEmissionDirective = createAsyncThunk('jobs/fetchEmissionDirective', async ({ chainId, emissionClass }, API) => {
  const queryData = {
    procName: 'GetEmissionDirective',
    data: { emissionClass },
  };
  const state = API.getState();
  const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return result;
});

export const fetchDocumentBlobToken = createAsyncThunk('jobs/fetchDocumentBlobToken', async ({ chainId, siteId, jobId, documentId, extension }, API) => {
  const state = API.getState();
  return await sendSiteRequest(chainId, siteId, 'GET', `/job/${jobId}/${documentId}/blobdocumenttoken`, `&extension=${extension}`, null, state.auth.token);
});

export const clearAtjError = createAsyncThunk('jobs/clearAtjError', async ({ chainId, siteId, jobId }, API) => {
  const queryData = {
    procName: 'ClearJobAtjErrorStatus',
    data: { chainId, siteId, jobId },
  };
  const state = API.getState();
  const result = await sendRequest(chainId, queryData, 'POST', state.auth.token);
  return result;
});

export const generateCertificate = createAsyncThunk('devices/generateCertificate', async ({ chainId, jobId, userId, printerId, printLanguage, useSitePrint, barcode }, API) => {
  const state = API.getState();
  const payload = {
    userId,
    printerId,
    printLanguage,
    PrintWithDorisSitePrint: useSitePrint,
    barcode,
  };
  const response = await sendJobRequest(chainId, jobId, 'POST', '/generateCertificate', payload, '', state.auth.token);
  return response;
});

export const annulJob = createAsyncThunk('jobs/annulJob', async ({ chainId, jobId, data }, API) => {
  const state = API.getState();
  const queryData = {
    procName: 'annulJob',
    data: data,
  };
  return await sendRequest(chainId, queryData, 'POST', state.auth.token);
});

const DEFAULT_RESULT_FORM_DATA = {
  [jobConstants.USER_ID]: null,
  [jobConstants.RESULT]: null,
  [jobConstants.ATJ_INTERRUPTED_REASON_CODE]: null,
  [jobConstants.ATJ_DISQUALIFIED_REASON_CODE]: null,
  [jobConstants.DO_NOT_UPDATE_ATJ]: false,
  [jobConstants.RESULT_DESCRIPTION]: '',
  [jobConstants.CERTIFICATE_NUMBER]: '',
  [jobConstants.VALIDITY_DATE]: null,
  [jobConstants.SET_NEW_VAK_EXTENSION_DATE]: false,
  [jobConstants.VAK_EXTENSION_DATE]: null,
  [jobConstants.NEW_VAK_EXTENSION_DATE]: null,
  [jobConstants.ATJ_PRINTER_ID]: null,
  [jobConstants.PRINTER_ID]: null,
  [jobConstants.COPIES]: '1',
  [jobConstants.PRINT_LANGUAGE]: 'fi-FI',
  [jobConstants.PRINT_IMAGES]: false,
  [jobConstants.PRINT_USE_DORIS_SITE_PRINT]: false,
  [jobConstants.REPAIR_DEADLINE_AT_DATE]: null,
  [jobConstants.REPAIR_DEADLINE_AT_TIME]: null,
  [jobConstants.REPAIR_SHOP_ADDRESS]: '',
  [jobConstants.DRIVING_BAN_CODE]: '',
  [jobConstants.AFTER_INSPECTION_DATE]: null,
  [jobConstants.AFTER_INSPECTION_PRICE]: null,
  [jobConstants.SECOND_USER_ID]: null,
  [jobConstants.AFTER_INSPECTION_PRODUCT_ID]: null,
};

const jobSlice = createSlice({
  name: 'job',
  initialState: {
    jobs: [],
    jobQueue: [],
    job: null,
    requiredMeasurements: [],
    jobImages: [],
    isFetching: false,
    fetchError: null,
    isUploading: false,
    vehicleColors: [],
    fuelTypes: [],
    vehicleClasses: [],
    addFaultOpen: false,
    addFaultCodePrefix: '',
    addFaultLocationId: '',
    addFaultSeverity: '',
    addFaultAdditionalInformation: '',
    addFaultDeadline: '',
    favoriteFaults: [],
    vehicleBrands: [],
    emissionDirective: null,
    resultData: {
      ...DEFAULT_RESULT_FORM_DATA,
    }
  },
  reducers: {
    openAddFault(state, { payload }) {
      state.addFaultOpen = true;
      if (payload) {
        state.addFaultCodePrefix = payload.addFaultCodePrefix; // Contains main fault ID (1 digit), sub fault ID (2 digits), component fault ID (2 digits), fault ID (1-3 digits)
        state.addFaultLocationId = payload.addFaultLocationId;
        state.addFaultSeverity = payload.addFaultSeverity;
        state.addFaultAdditionalInformation = payload.addFaultAdditionalInformation;
        state.addFaultDeadline = payload.addFaultDeadline;
      }
    },
    closeAddFault(state) {
      state.addFaultOpen = false;
      state.addFaultCodePrefix = '';
      state.addFaultLocationId = '';
      state.addFaultSeverity = '';
      state.addFaultAdditionalInformation = '';
      state.addFaultDeadline = '';
    },
    storeResultData: (state, { payload }) => {
      state.resultData = payload
    },
    clearResultData: (state) => {
      state.resultData = DEFAULT_RESULT_FORM_DATA
    },
    resetJobState: (state) => {
      state.job = null;
      state.requiredMeasurements = [];
      state.jobImages = [];
      state.isFetching = false;
      state.fetchError = null;
      state.isUploading = false;
      state.vehicleColors = [];
      state.fuelTypes = [];
      state.vehicleClasses = [];
      state.addFaultOpen = false;
      state.addFaultCodePrefix = '';
      state.addFaultLocationId = '';
      state.addFaultSeverity = '';
      state.addFaultAdditionalInformation = '';
      state.addFaultDeadline = '';
      state.favoriteFaults = [];
      state.vehicleBrands = [];
      state.emissionDirective = null;
      state.resultData = DEFAULT_RESULT_FORM_DATA;
    }
  },
  extraReducers: {
    [negotiateMeasurement.fulfilled]: (state, {payload}) => {
      state.socketUrl = payload?.url ?? null;
    },
    [receiveMeasurementResult.fulfilled]: (state, {payload}) => {
      if (payload.consumption) {
        const consumptionMeasurement = state.job.measurementInformation.find(m => m.measurementType.name === jobConstants.CONSUMPTION);

        if (consumptionMeasurement) {
          consumptionMeasurement.measurementValues = JSON.stringify(payload.consumption)
        } else {
          state.job.measurementInformation.push({
            measurementType: {
              name: jobConstants.CONSUMPTION,
            },
            measurementValues: JSON.stringify(payload.consumption),
          })
        }
      }

      if (payload.emission) {
        const emissionMeasurement = state.job.measurementInformation.find(m => m.measurementType.name === jobConstants.EMISSION);

        const formattedEmissionValues = {
          ...payload.emission,
          [jobConstants.EMISSIONS_CO_IDLE]: (payload.emission[jobConstants.EMISSIONS_CO_IDLE] ?? 0),
          [jobConstants.EMISSIONS_HC_IDLE]: (payload.emission[jobConstants.EMISSIONS_HC_IDLE] ?? 0),
          [jobConstants.EMISSIONS_O2_IDLE]: (payload.emission[jobConstants.EMISSIONS_O2_IDLE] ?? 0),
          [jobConstants.EMISSIONS_CO2_IDLE]: (payload.emission[jobConstants.EMISSIONS_CO2_IDLE] ?? 0),
          [jobConstants.EMISSIONS_RPM_IDLE]: (payload.emission[jobConstants.EMISSIONS_RPM_IDLE] ?? 0),
          [jobConstants.EMISSIONS_CO_REV]: (payload.emission[jobConstants.EMISSIONS_CO_REV] ?? 0),
          [jobConstants.EMISSIONS_CO2_REV]: (payload.emission[jobConstants.EMISSIONS_CO2_REV] ?? 0),
          [jobConstants.EMISSIONS_HC_REV]: (payload.emission[jobConstants.EMISSIONS_HC_REV] ?? 0),
          [jobConstants.EMISSIONS_O2_REV]: (payload.emission[jobConstants.EMISSIONS_O2_REV] ?? 0),
          [jobConstants.EMISSIONS_RPM_REV]: (payload.emission[jobConstants.EMISSIONS_RPM_REV] ?? 0),
          [jobConstants.EMISSIONS_LAMBDA_REV]: (payload.emission[jobConstants.EMISSIONS_LAMBDA_REV] ?? 0),
        };

        if (emissionMeasurement) {
          const previousEmissionValues = JSON.parse(emissionMeasurement.measurementValues)

          if (payload.measurementType === 'emission-idle') {
            emissionMeasurement.measurementValues = JSON.stringify({
              ...previousEmissionValues,
              [jobConstants.EMISSIONS_READ_AT]: formattedEmissionValues[jobConstants.EMISSIONS_READ_AT],
              [jobConstants.EMISSIONS_IDLE_READ_AT]: formattedEmissionValues[jobConstants.EMISSIONS_READ_AT],
              [jobConstants.EMISSIONS_READ_ERROR_CODE]: formattedEmissionValues[jobConstants.EMISSIONS_READ_ERROR_CODE],
              [jobConstants.EMISSIONS_CO_IDLE]: formattedEmissionValues[jobConstants.EMISSIONS_CO_IDLE],
              [jobConstants.EMISSIONS_CO2_IDLE]: formattedEmissionValues[jobConstants.EMISSIONS_CO2_IDLE],
              [jobConstants.EMISSIONS_HC_IDLE]: formattedEmissionValues[jobConstants.EMISSIONS_HC_IDLE],
              [jobConstants.EMISSIONS_O2_IDLE]: formattedEmissionValues[jobConstants.EMISSIONS_O2_IDLE],
              [jobConstants.EMISSIONS_RPM_IDLE]: formattedEmissionValues[jobConstants.EMISSIONS_RPM_IDLE],
            });
          } else if (payload.measurementType === 'emission-rev') {
            emissionMeasurement.measurementValues = JSON.stringify({
              ...previousEmissionValues,
              [jobConstants.EMISSIONS_READ_AT]: formattedEmissionValues[jobConstants.EMISSIONS_READ_AT],
              [jobConstants.EMISSIONS_REV_READ_AT]: formattedEmissionValues[jobConstants.EMISSIONS_READ_AT],
              [jobConstants.EMISSIONS_READ_ERROR_CODE]: formattedEmissionValues[jobConstants.EMISSIONS_READ_ERROR_CODE],
              [jobConstants.EMISSIONS_CO_REV]: formattedEmissionValues[jobConstants.EMISSIONS_CO_REV],
              [jobConstants.EMISSIONS_CO2_REV]: formattedEmissionValues[jobConstants.EMISSIONS_CO2_REV],
              [jobConstants.EMISSIONS_HC_REV]: formattedEmissionValues[jobConstants.EMISSIONS_HC_REV],
              [jobConstants.EMISSIONS_O2_REV]: formattedEmissionValues[jobConstants.EMISSIONS_O2_REV],
              [jobConstants.EMISSIONS_RPM_REV]: formattedEmissionValues[jobConstants.EMISSIONS_RPM_REV],
              [jobConstants.EMISSIONS_LAMBDA_REV]: formattedEmissionValues[jobConstants.EMISSIONS_LAMBDA_REV],
            });
          } else {
            emissionMeasurement.measurementValues = JSON.stringify(formattedEmissionValues);
          }
        } else {
          state.job.measurementInformation.push({
            measurementType: {
              name: jobConstants.EMISSION,
            },
            measurementValues: JSON.stringify(formattedEmissionValues),
          })
        }
      }

      if (payload.obd) {
        const obdMeasurement = state.job.measurementInformation.find(m => m.measurementType.name === jobConstants.OBD);

        if (obdMeasurement) {
          obdMeasurement.measurementValues = JSON.stringify(payload.obd)
        } else {
          state.job.measurementInformation.push({
            measurementType: {
              name: jobConstants.OBD,
            },
            measurementValues: JSON.stringify(payload.obd),
          })
        }
      }
    },
    [fetchJobs.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchJobs.fulfilled]: (state, { payload }) => {
      state.jobs = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    [fetchJobs.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.fetchError = payload ?? 'fetchJobs.rejected';
    },
    [fetchJobQueue.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchJobQueue.fulfilled]: (state, { payload }) => {
      state.jobQueue = payload;
      state.isFetching = false;
      state.fetchError = null;
    },
    [fetchJobQueue.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.fetchError = payload ?? 'fetchJobQueue.rejected';
    },
    [updateJob.fulfilled]: (state, { payload }) => {
      // Update only registration number for now, if there is an active job.
      if (state.job) {
        state.job.registrationNumber = payload.registrationNumber;
        state.job.lineId = payload.lineId;
        state.job.status = payload.status;
      }
    },
    [deleteJob.fulfilled]: (state, { payload }) => {
      state.jobQueue = state.jobQueue.filter(job => job.id !== payload);
    },
    [annulJob.fulfilled]: (state, { payload }) => {
      if (state.job) {
        state.job.status = payload.status;
        state.job.annulledAt = payload.annulledAt;
        state.job.annulledById = payload.annulledById;
        state.job.atjResult = payload.atjResult;
        state.job.annulledByUserFirstName = payload.annulledByUserFirstName;
        state.job.annulledByUserLastName = payload.annulledByUserLastName;
      }
    },
    [fetchJob.pending]: (state) => {
      state.job = null;
    },
    [fetchJob.fulfilled]: (state, { payload }) => {
      state.job = {
        ...payload,
        productInformation: payload.productInformation || [],
      };
    },
    [fetchRequiredMeasurements.fulfilled]: (state, { payload }) => {
      const identificationMeasurement = payload.find(measurement => measurement.measurementType.name === jobConstants.IDENTIFICATION);
      const informationMeasurement = payload.find(measurement => measurement.measurementType.name === jobConstants.INFORMATION);
      const resultMeasurement = payload.find(measurement => measurement.measurementType.name === jobConstants.RESULT);
      const otherMeasurements = payload.filter(measurement => {
        return measurement.measurementType.name !== jobConstants.IDENTIFICATION && measurement.measurementType.name !== jobConstants.INFORMATION
          && measurement.measurementType.name !== jobConstants.RESULT;
      });
      const sortedMeasurements = [
        identificationMeasurement,
        informationMeasurement,
        ...otherMeasurements,
        resultMeasurement,
      ];
      state.requiredMeasurements = sortedMeasurements;
    },
    [fetchJobImages.pending]: (state) => {
      state.jobImages = [];
    },
    [fetchJobImages.fulfilled]: (state, { payload }) => {
      state.jobImages = payload;
    },
    [uploadImage.pending]: (state) => {
      state.isUploading = true;
    },
    [uploadImage.fulfilled]: (state) => {
      state.isUploading = false;
    },
    [uploadImage.rejected]: (state) => {
      state.isUploading = false;
    },
    [deleteImage.fulfilled]: (state, { payload }) => {
      // Filter out deleted images.
      const ids = payload.id.split('-');
      state.jobImages = state.jobImages.filter(image => ids.indexOf(image.id.toString()) === -1);
    },
    [uploadVideo.pending]: (state) => {
      state.isUploading = true;
    },
    [uploadVideo.fulfilled]: (state) => {
      state.isUploading = false;
    },
    [uploadVideo.rejected]: (state) => {
      state.isUploading = false;
    },
    [fetchVehicleColors.fulfilled]: (state, { payload }) => {
      state.vehicleColors = payload;
    },
    [fetchFuelTypes.fulfilled]: (state, { payload }) => {
      state.fuelTypes = payload;
    },
    [fetchVehicleClasses.fulfilled]: (state, { payload }) => {
      state.vehicleClasses = payload;
    },
    [updateMeasurementInformation.fulfilled]: (state, { payload }) => {
      state.job.measurementInformation = payload;
    },
    [updateFaultInformation.fulfilled]: (state, { payload }) => {
      state.job.faultInformation = payload;
    },
    [updateVehicleInformation.fulfilled]: (state, { payload }) => {
      state.job.vehicleInformation = payload.vehicleInformation;
      if (payload.vehicleInformation && payload.vehicleInformation[jobConstants.ODOMETER_READING]) {
        state.resultData[jobConstants.ODOMETER_READING] = payload.vehicleInformation[jobConstants.ODOMETER_READING];
      }
      if (payload.vehicleInformation && payload.vehicleInformation[jobConstants.ODOMETER_TYPE]) {
        state.resultData[jobConstants.ODOMETER_TYPE] = payload.vehicleInformation[jobConstants.ODOMETER_TYPE];
        if (payload.vehicleInformation[jobConstants.ODOMETER_TYPE] === jobConstants.NO_ODOMETER) {
          state.resultData[jobConstants.ODOMETER_READING] = null;
        }
      }
    },
    [updateHaynesInformation.pending]: (state) => {
      state.job.haynesInformation = null;
    },
    [updateHaynesInformation.fulfilled]: (state, { payload }) => {
      state.job.haynesInformation = payload;
    },
    [fetchFavoriteFaults.fulfilled]: (state, { payload }) => {
      // Convert object keys from pascal case to camel case.
      state.favoriteFaults = payload && payload.length > 0 ? payload.map((fault) => {
        const result = {};
        const keys = Object.keys(fault);
        keys.forEach((key) => {
          const newKey = (key.charAt(0).toLowerCase() + key.slice(1)).toString();
          result[newKey] = fault[key];
        });
        return result;
      }) : [];
    },
    [updateFavoriteFaults.fulfilled]: (state, { payload }) => {
      state.favoriteFaults = payload;
    },
    [createFault.fulfilled]: (state, { payload }) => {
      state.job.faultInformation = payload;
    },
    [fetchVehicleBrands.fulfilled]: (state, { payload }) => {
      state.vehicleBrands = payload;
    },
    [fetchEmissionDirective.fulfilled]: (state, { payload }) => {
      state.emissionDirective = payload;
    },
    [generateCertificate.fulfilled]: (state, { payload }) => {
      // payload is an array of documentInformation elements. Extend the current documentInformation array.
      // By placing the the new elements first, the new elements will be shown first in the list.
      state.job.documentInformation = [...payload, ...(state.job.documentInformation || [])];
    },
  },
});

export const {
  openAddFault,
  closeAddFault,
  storeResultData,
  clearResultData,
  resetJobState,
} = jobSlice.actions;

export default jobSlice.reducer;
